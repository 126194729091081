import {useEffect, useState} from "react";
import {checkLogin} from "../../../../../@WUM/templates/default/Auth/service/AuthService";
import {error} from "../../../../../@WUM/core/Elements/index";
import {participantInfo, profilApiType} from "../Request/profilAPI.interface";
import {profil} from "../Request/profil";
import {InvoiceDataModel} from "../../../../components/Elements/pdf/Model/InvoiceDataModel";
import {invoiceAPIOverview} from "./invoiceAPI.interface";
import {invoice} from "./invoice";
// @ts-ignore
import {Notification} from "create-notifications/notfication";

export const Responseinvoice = (id: any) => {
    const [posts, setPosts] = useState<profilApiType>()
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await profil
                .getPostsinvoice(id)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? isError : posts
}

export const Responsecertificate = (id: any) => {
    const [posts, setPosts] = useState<profilApiType>()
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await profil
                .getPostsinvoice(id)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? isError : posts
}

export const ResponseInvoiceDetail = (id: number) => {
    const [posts, setPosts] = useState<InvoiceDataModel>()
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await profil
                .getInvoiceDetail(id) /* <-- Point falls neuer geschaffen tauschen sonst nix verändern */
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? isError : posts
}

export const ResponseInvoiceOverview = (limit:any,searchValue:any) => {
    const [posts, setPosts] = useState<InvoiceDataModel[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await profil.getInvoiceOverview(limit)
                .then((data) => {
                    checkLogin(data)
                    if(data.hasOwnProperty('msg')) {
                        Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: 'Unfortunately, something went wrong'}, insert:'top',duration:4})
                    }
                    setPosts(data);
                })
                .catch((err) => {
                    Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: err}, insert:'top',duration:4})
                    setIsError(true);
                });
        })()}, [limit])


    return isError ? (posts ) : (posts);

}

export const ResponseInvoiceOverviewUser = (limit:any,searchValue:any, id:any) => {
    const [posts, setPosts] = useState<InvoiceDataModel[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await profil.getInvoiceOverviewUser(limit, id)
                .then((data) => {
                    checkLogin(data)
                    if(data.hasOwnProperty('msg')) {
                        Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: 'Unfortunately, something went wrong'}, insert:'top',duration:4})
                    }
                    setPosts(data);
                })
                .catch((err) => {
                    Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: err}, insert:'top',duration:4})
                    setIsError(true);
                });
        })()}, [limit, id])


    return isError ? (posts ) : (posts);

}

export const ResponseParticipantInfo = (id: any) => {
    const [posts, setPosts] = useState<participantInfo>()
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await profil
                .getParticipant(id)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? isError : posts
}

export const ResponseInvoiceAll = (year: number) => {
    const [posts, setPosts] = useState<InvoiceDataModel[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await profil.getAllInvoices(year)
                .then((data) => {
                    checkLogin(data)
                    if(data.hasOwnProperty('msg')) {
                        Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: 'Unfortunately, something went wrong'}, insert:'top',duration:4})
                    }
                    setPosts(data);
                })
                .catch((err) => {
                    Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: err}, insert:'top',duration:4})
                    setIsError(true);
                });
        })()}, [year])


    return isError ? (posts ) : (posts);

}
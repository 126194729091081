import {requests} from "../../../../../@WUM/core/RestFullApi/api";
import {participantInfo, profilApiType} from "./profilAPI.interface";
import {InvoiceDataModel} from "../../../../components/Elements/pdf/Model/InvoiceDataModel";


export const profil ={
    getPostsinvoice: (id:any): Promise<profilApiType> =>
        requests.get(`Conf/invoice/bulk/${id}/`),
    getPostscertificate: (id:any): Promise<profilApiType> =>
        requests.get(`Conf/certificate/bulk/${id}/`),
    getInvoiceOverview: (limit:any): Promise<InvoiceDataModel[]> =>
        requests.get(`/conf/invoice/all/byoffset/${limit}/`),
    getInvoiceOverviewUser: (limit:any, id:any): Promise<InvoiceDataModel[]> =>
        requests.get(`/conf/invoice/all/user/${id}/${limit}/`),
    getInvoiceDetail: (id:any): Promise<InvoiceDataModel> =>
        requests.get(`conf/invoice/${id}/`),
    getParticipant: (id:any): Promise<participantInfo> =>
        requests.get(`event/participant/${id}/`),
    getAllInvoices: (year: number) : Promise<any> => requests.get(`/conf/invoice/all/by/event/${year}/`)
}

import {SpinnerJSX, UniCol, UniRow} from "../../../../../@WUM/core/Elements";
import {useSelector} from "react-redux";
import {ResponseInvoiceAll} from "../../API/Response/profil";
import {Document, PDFViewer} from "@react-pdf/renderer";
import InvoicePDFController from "../../../../components/Elements/pdf/Controller/InvoiceController";
import React from "react";
import {useLocation} from "react-router-dom";

const InvoiceIcymareDetail = (props:{value: any, year?:number}) => {
    const Store = useSelector((state: any) => state)
    const location = useLocation();
    const year = location.state?.year; // Abruf des `state`

    const response = ResponseInvoiceAll(year)

    console.log(year)


    if (response !== null && Array.isArray(response) && response.length !== 0 && year) {
        return (
            <>
                <UniRow>
                    <h3>Invoices 20{year}</h3>
                    <UniCol xs={12}>
                        <PDFViewer width={"100%"} height={"750px"} className={"col-8"}>
                            <Document>
                                {response.map((entry, index) => {
                                    if (
                                        entry.id &&
                                        entry.countryName &&
                                        typeof entry.countryName.valueDE === "string"
                                    ) {
                                        return (
                                            <InvoicePDFController response={entry}/>
                                        );
                                    } else {
                                        return (
                                            <>leer</>
                                        ); // Noch keine Anzeige
                                    }

                                })}
                            </Document>
                        </PDFViewer>
                    </UniCol>
                </UniRow>
            </>
        )

    } else if (!year) {
        return (
            <>
                <div>Year missing</div>
            </>
        );
    } else {
        return (
            <>No invoices available</>
        )
    }
}

export default InvoiceIcymareDetail;
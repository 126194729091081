import React from "react";
import { useNavigate } from "react-router-dom";
import { UniButton } from "../../../../../@WUM/core/Elements";

const InvoiceIcymare = (props: { value: any }) => {
    const navigate = useNavigate();

    const handleNavigation = (year: number) => {
        // Navigiere mit einem `state`-Objekt und dem übergebenen Jahr
        navigate("/invoice-icymare-detail/", { state: { year } });
    };

    return (
        <div>
            <h3>Rechnungsjahr auswählen</h3>
                <UniButton children={"2024"} onClick={() => handleNavigation(24)} class={"me-3"} />
                <UniButton children={"2025"} onClick={() => handleNavigation(25)} />
        </div>
    );
};

export default InvoiceIcymare;
